/* background-color: var(--color-background); */


.custom-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.login-vector-image {
    min-width: 300px;
    /* max-width: 1200px; */
}

.secondary-color {
    background-color: var(--secondary-color);
}

.text-color-primary {
    color: var(--primary-color);
}

.text-color-secondary {
    color: var(--secondary-color);
}

.primary-color {
    background-color: var(--primary-color);
}

.custom-box-left-inner {
    padding: 50px;
    width: 100%;
    max-width: 700px;
    min-width: 300px;
}

.button_style {
    background-color: var(--primary-color) !important;
}

.logout-btn {
    color: #ff0000;
    font-weight: 900 !important;
}

.sidebar-subitem span {
    font-size: 12px;
    font-weight: 500;
    color: var(--half-white);
}

.sidebar-subitem-icon {
    color: var(--half-white);
}

.app-bar {
    background-color: var(--secondary-color) !important;
}

.side-bar {
    background-color: var(--secondary-color) !important;
    z-index: 99;
}

.side-menu-items-icons {
    color: var(--white-color);
}

.side-menu-items {
    color: var(--white-color);
}

.side-menu-items span {
    font-size: 14px;
}

.toggle-side-btn :hover {
    background-color: var(--half-white) !important;
}

.dashboard_card_title {
    color: var(--secondary-color);
}

.card-value {
    color: var(--half-white);
    font-size: 32px;
}

.table_container {
    padding: 10px;
}

.add_service_text_input {
    width: 400px;
}

.btn_primaryColor {
    background-color: var(--primary-color) !important;
}

.add_more {
    cursor: pointer;
    transition: transform 0.5s ease;
    color: var(--half-white);
    position: relative;
    transform-origin: center;
    flex-grow: 1;
    width: auto;
}

.add_more:hover {
    transform: scale(1.1);
    color: var(--secondary-color);
    transform-origin: center;
    /* Specify the transform origin */
}

.delete_item:hover {
    color: #ff0000;
}

.heading_button{
    font-size: 12px;
    border-radius: 5px;
    border: none;
    background-color: var(--heading-color);
    color: white;
    padding:5px 10px 5px 10px;
    cursor: pointer;
    text-align: center;
}
.heading_button:hover{
    background-color: 'transparent';
    border: 2px double #cccccc;
}

.counting-cards{
    cursor: pointer;
}